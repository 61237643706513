import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import 'semantic-ui-css/semantic.min.css'

import Header from './components/header/header'
import Footer from './components/footer/footer'
import Page from './components/pages/page'

function App() {
  return (
    <div>
      <BrowserRouter>
        <Header/>
        <Page/>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
