import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { 
  Segment,
  Header,
  Grid,
  List,
  Container,
  Divider,
  Image
 } from 'semantic-ui-react';

class Footer extends Component {
  render() {
    return (
      <Segment inverted vertical style={{ margin: '0em 0em 0em', padding: '3.5em 0em', backgroundColor: '#22334d' }}>
        <Container textAlign='center'>
          
          <Divider inverted section />
          <Grid divided inverted stackable columns={3}>
            <Grid.Column>
              <Header inverted as='h4' content='Unternehmen' />
              <List link inverted>
                <List.Item as={Link} to='/company'>Was wir machen</List.Item>
                <List.Item as={Link} to='/team'>Wer wir sind</List.Item>
              </List>
            </Grid.Column>

            <Grid.Column>
              <Header inverted as='h4' content='Information' />
              <List link inverted>
                <List.Item as={Link} to='/jobs'>Jobs</List.Item>
                <List.Item as={Link} to='/impressum'>Impressum</List.Item>
              </List>
            </Grid.Column>

            <Grid.Column>
              <Header inverted as='h4' content='Get in touch' />
              <List link inverted>
                <List.Item as={Link} to='/contact'>Kontakt</List.Item>
                <List.Item as='a' target='_blank' href='https://www.linkedin.com/company/m-rig/'>Linkedin</List.Item>
              </List>
            </Grid.Column>
          </Grid>

          <Divider inverted section />
          <Image centered size='tiny' src={process.env.PUBLIC_URL + '/logo.png'} />
          <List horizontal inverted divided link size='small'>
            <List.Item as='a' target='_blank' href='https://www.mrig.de'>
              Copyright {new Date().getFullYear()}, mrig GmbH
            </List.Item>
          </List>
        </Container>
      </Segment>
    );
  }
}

export default Footer;