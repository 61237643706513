import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Header,
  Segment,
  Divider,
  Image,
  Modal,
  Button,
  Icon,
  List,
  Grid
} from 'semantic-ui-react'

class CompanyPageLayout extends Component {
  DescriptionText(text) {
    if (text === 'rmDE') {
      return (
        <Modal.Content scrolling>
          <center>
            <Image 
              src={'/riskmanagement.jpg'} 
              style={{maxHeight: '300px'}} 
              rounded
            />
          </center>
          <Divider hidden />
          <Modal.Description>
              <p>
                Das Risikomanagement der Finanzinstitute unterliegt einer zunehmenden Regulierung und ist von einer vielseitigen Komplexität geprägt: Einsatz und Pflege mathematischer Modelle sowie der zugrunde liegenden Datenhistorie, zerstückelte und/oder veraltete IT-Landschaften, steigende Anforderungen an das Reporting und nicht zuletzt die Notwendigkeit einer wirksamen Risikosteuerung alles unter wachsendem Kostendruck.
              </p>
              <p>
                Mit dem Bewusstsein der gestellten Herausforderungen, mit vertieften Kenntnissen in quantitativen Methoden und mit einer breiten Erfahrung rund um das Risikomanagement unterstützen wir unsere Kunden bei der (Weiter-) Entwicklung von Risikomethoden und -systeme sowie bei der Erfüllung neuer Regulierungen.
              </p>
              <p>
              Wir bieten unter anderem eine solide Expertise und Projekterfahrung in den folgenden Gebieten:
              </p>
              <List bulleted>
                <List.Item>Entwicklung und Validierung von PD-, LGD- und EAD-Modellen für verschiedene Segmente (Retail, Corporate, SPV, Sovereign...)</List.Item>
                <List.Item>Aufsichtsrecht / IRBA-Ratingsysteme</List.Item>
                <List.Item>Entwicklung und Validierung von Kreditportfoliomodellen</List.Item>
                <List.Item>Risikovorsorge (inkl. Expected Loss Model im Rahmen des IFRS 9)</List.Item>
                <List.Item>Risikoaggregation und Reporting</List.Item>
                <List.Item>Risikobasierte Preisgestaltung</List.Item>
                <List.Item>Antragsprozessoptimierung (z. B. anhand Girokonto- und Kontokorrentkreditdaten)</List.Item>
                <List.Item>ICAAP-Prozesse</List.Item>
                <List.Item>Stress-Testing</List.Item>
                <List.Item>Liquiditätsrisiko</List.Item>
              </List>
              <Divider hidden />
          </Modal.Description>
        </Modal.Content>
      )
    }
    else if (text === 'mlDE') {
      return (
        <Modal.Content scrolling>
          <center>
            <Image 
              src={'/machinelearning.jpg'} 
              style={{maxHeight: '300px'}} 
              rounded
            />
          </center>
          <Divider hidden />
          <Modal.Description>
              <p>Immer mehr Unternehmen integrieren künstliche Intelligenz in ihre Prozesse und Entscheidungen. Die ersten Hürden sind die Auswahl der richtigen Use Cases, Entscheidungsfindungen und die der Modellentwicklung samt Implementierung. Die eigentliche Entwicklung der Modelle kann durch Data Scientists kompakt erledigt werden. Hierbei muss jedoch die Umsetzung mitbedacht werden, denn die IT-Infrastruktur bietet oft nicht die Möglichkeit einer schnellen ad hoc Umsetzung.</p>
              <p>Wir unterstützen Sie von der Konzeption bis zur Umsetzung. Unsere erfahrenen Data Scientist erörtern mit Ihnen Ihre Ideen und Use Cases und unterstützen Sie beim Aufdecken weiterer Potentiale für den Einsatz von A.I. in Ihrem Unternehmen.</p>
              
              <Divider hidden></Divider>
              
              <Grid divided='vertically' columns={2}>
                <Grid.Column>
                  <Image src={'/team2-2.jpg'} rounded />
                </Grid.Column>

                <Grid.Column>
                  <h3>Unser Data Lab</h3>
                  <p>In unserem Data Lab können wir konkrete Use Cases anhand Originaldaten überprüfen und die für Ihr Unternehmen nutzenstiftenden Ideen identifizieren.</p>
                  <h3>Wie läuft ein Data Lab ab?</h3>
                  <List bulleted>
                    <List.Item>Identifikation und Bewertung von Use Cases</List.Item>
                    <List.Item>Beurteilung der Datengrundlage</List.Item>
                    <List.Item>Entwicklung eines Prototyps</List.Item>
                    <List.Item>Auswirkungsanalyse und Erfolgsmessung</List.Item>
                    <List.Item>Empfehlung über die Umsetzung</List.Item>
                  </List>
                </Grid.Column>
              </Grid>

              <Divider hidden></Divider>

              <p>Wichtig: In allen Schritten sind sowohl Data Scientists als auch Spezialisten aus dem Fachbereich notwendig. Ob Schulung, Workshop oder Hackathon - wir bieten Ihren Mitarbeitern und Ihnen ein angenehmes Off-Site-Setup um fachliches Know-How aufzubauen und konkrete Problemstellungen zu lösen.</p>
              <h3>Ihre Benefits</h3>
              <List bulleted>
                <List.Item>Wir stellen Ihnen Räume, Hardware und Software zur Verfügung.</List.Item>
                <List.Item>Unsere erfahrenen Data Scientist übernehmen den Lead oder unterstützen Ihre Experten. Wir teilen gerne unser Know-How und planen Schulungs-Sessions mit ein.</List.Item>
                <List.Item>Unser Rechenserver genügt den höchsten Sicherheitsstandards. Es ist aber auch möglich auf Ihren Servern zu arbeiten.</List.Item>
                <List.Item>Wir stellen verschiedene Softwaresetups für Modellentwicklung, Data Exploration und Visualisierung zur Verfügung (u.a. Python, R, ...).</List.Item>
                <List.Item>Unsere Räume bieten ein angenehmes Off-Site-Setup (inklusive Versorgung).</List.Item>
              </List>
              <p>Kontaktieren Sie uns, um Ihre Ideen zu konkretisieren und/oder ein Angebot zu erhalten.</p>
              <Button as={Link} to='/contact' primary style={{backgroundColor: '#3DA7CA'}}>
                <Icon name='mail' /> Kontakt
              </Button>
              <Divider hidden />
          </Modal.Description>
        </Modal.Content>
      )
    }
    else if (text === 'pmDE') {
      return (
        <Modal.Content scrolling>
          <center>
            <Image 
              src={'/projectmanagement.jpg'} 
              style={{maxHeight: '300px'}}
              rounded
            />
          </center>
          <Divider hidden />
          <Modal.Description>
            <p>Projektmanagement stellt einen signifikanten Kostenblock in der Projektlandschaft dar. Doch ohne ein gezieltes Management der Projekte wird der Erfolg zum Glücksspiel.</p>
            <p>Wir zeichnen uns mit klaren Strukturen und stringenter Organisation für ein schlankes Projektmanagement aus, welches den Overhead reduziert und damit Kosten einspart. Hier stehen wir für Verbindlichkeit, Transparenz und Zielstrebigkeit im Management von Projekten. Neben dem organisatorischen Projektmanagement ist die Stimmung im Team ein signifikanter Treiber für die Performance und in unserem Ansatz ein fester Bestandteil.</p>
            <p>Die Management-Methode wählen wir nach objektiven Kriterien und führen diese professionell durch. Wir bieten drei Projektmanagementansätze an:</p>
            <List bulleted>
              <List.Item>
                <List.Content>
                  <List.Header>Kanban</List.Header>
                  <List.Description>Kanban ist eine agile Projektmanagementmethode mit sehr geringem Overhead. Die Anzahl der parallel bearbeiteten Aufgaben ist ein zentrales Steuerungselement. So werden kurze Durchlaufzeiten bei hoher Transparenz von Engpässen erreicht.</List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <List.Header>Scrum</List.Header>
                  <List.Description>Scrum zeichnet sich durch wohldefinierte Iterationen (Sprints) mit klarem Zeitrahmen, Rollen und Aufgaben aus. Die einzelnen Sprints sind kurz, bspw. zwei Wochen. Nach jeder Iteration findet ein Review statt und Verbesserungspotentiale im Vorgehen werden realisiert.</List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <List.Header>Wasserfall</List.Header>
                  <List.Description>Im klassischen Projektmanagement wird im Vorfeld ein detaillierter Projektplan erstellt. Dazu gehören i. d. R. Meilensteine, ein kritischer Pfad und eine Chancen-und-Risiken Analyse. Der Overhead im Vorfeld des Projekts zahlt sich durch ein stabiles Umfeld im Projektverlauf aus.</List.Description>
                </List.Content>
              </List.Item>
            </List>
            <Divider hidden />
        </Modal.Description>
      </Modal.Content>
      )
    }
    else {
      return ('')
    }
   }

  Modal(text) {
    var header = ''
    if (text === 'rmDE') {
      header = 'Risikomanagement'
    }
    else if (text === 'mlDE') {
      header = 'Machine Learning'
    }
    else if (text === 'pmDE') {
      header = 'Projektmanagement'
    }

    return (
      <Modal dimmer='blurring' trigger={<Button primary style={{backgroundColor: '#22334D'}}>Mehr lesen<Icon name='right chevron' /> </Button>}>
        <Modal.Header>{header}</Modal.Header> 
        {this.DescriptionText(text)}
        
      </Modal>
    )
  }

	Heading() {
    return (
      <div
        inverted
            textAlign='center'
            style={{ minHeight: 350, 
              padding: '0em 0em',
              backgroundColor:'rgba(0,0,0,0.5)',
              backgroundImage: `url(${process.env.PUBLIC_URL + '/homepage.jpg'})`, 
              backgroundSize: 'cover',
              backgroundAttachment:'fixed' }}
            vertical>
        <Segment
            inverted
            textAlign='center'
            style={{ minHeight: 350, 
              padding: '1em 0em',
              backgroundColor:'rgba(0,0,0,0.4)',
              backgroundSize: 'cover',
              backgroundAttachment:'fixed' }}
            vertical
          >
        <Header
          as='h1'
          content='Leistungen'
          inverted
          style={{
            fontSize: '5.2em',
            fontWeight: 'normal',
            marginTop:  '1.7em',
          }}
        />
        <Header
          as='h2'
          content='Was wir machen'
          inverted
          style={{
            fontSize:  '1.7em',
            fontWeight: 'normal',
            marginTop:  '1em',
          }}
        />
      </Segment>
      </div>
      
    );
	};

	render () {
    const header = this.Heading()

    return (
        <div> 
          {header}
          <Container>
            <Segment style={{ margin: '1em 0em 0em', padding: '3em 0em' }} vertical>
              <Grid divided='vertically' columns={3}>
                <Grid.Row>
                  <Grid.Column mobile={16} tablet={8} computer={5}>
                    <Header as='h2' textAlign='center'>Risikomanagement</Header>
                    <Image src={'/riskmanagement.jpg'} rounded />
                    <Divider hidden></Divider>
                    <Container textAlign='justified' style={{minHeight: '80px'}}>Wir verfügen über vertiefte Kenntnisse in quantitativen Methoden und haben eine breite Erfahrung rund um das Risikomanagement in Finanzinstituten (inkl. aufsichtsrechtlichen Anforderungen).</Container>
                    <Divider hidden></Divider>
                    {this.Modal('rmDE')}
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={8} computer={5}>
                    <Header as='h2' textAlign='center'>Machine Learning</Header>
                    <Image src={'/machinelearning.jpg'} rounded />
                    <Divider hidden></Divider>
                    <Container textAlign='justified' style={{minHeight: '80px'}}>Künstliche Intelligenz hält Einzug in die Finanzbranche. Wir unterstützen mit unserer Erfahrung in Machine Learning und in der Finanzbranche bei der Analyse und Umsetzung von Use Cases.</Container>
                    <Divider hidden></Divider>
                    {this.Modal('mlDE')}
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={8} computer={5}>
                    <Header as='h2' textAlign='center'>Projektmanagement</Header>
                    <Image src={'/projectmanagement.jpg'} rounded />
                    <Divider hidden></Divider>
                    <Container textAlign='justified' style={{minHeight: '80px'}}>Egal ob Kanban, Scrum oder klassisches Projektmanagement, wir unterstützen Sie bei quantitativen Projekten und stellen den Projekterfolg mit Ihnen sicher.</Container>
                    <Divider hidden></Divider>
                    {this.Modal('pmDE')}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Container>
        </div>
  
      );
  }
};

export default CompanyPageLayout;
