import React, { Component } from 'react';
import {
	Container,
	Header,
  Segment,
  Grid,
  Image,
  Divider
} from 'semantic-ui-react'

class TeamPageLayout extends Component {
	Heading() {
    return (
      <div
        inverted
            textAlign='center'
            style={{ minHeight: 350, 
              padding: '0em 0em',
              backgroundColor:'rgba(0,0,0,0.5)',
              backgroundImage: `url(${process.env.PUBLIC_URL + '/homepage.jpg'})`, 
              backgroundSize: 'cover',
              backgroundAttachment:'fixed' }}
            vertical>
        <Segment
            inverted
            textAlign='center'
            style={{ minHeight: 350, 
              padding: '1em 0em',
              backgroundColor:'rgba(0,0,0,0.4)',
              backgroundSize: 'cover',
              backgroundAttachment:'fixed' }}
            vertical
          >
        <Header
          as='h1'
          content='Team'
          inverted
          style={{
            fontSize: '5.2em',
            fontWeight: 'normal',
            marginTop:  '1.7em',
          }}
        />
        <Header
          as='h2'
          content='Wer wir sind'
          inverted
          style={{
            fontSize:  '1.7em',
            fontWeight: 'normal',
            marginTop:  '1em',
          }}
        />
      </Segment>
      </div>
      
    );
	};

	render () {
    const header = this.Heading()
    return (
      <div> 
        {header}
        <Container textAlign='justified' >
          <Segment style={{  margin: '0em 0em 0em', padding: '3em 0em' }} vertical>
            <Segment style={{ padding: '5em 0em' }} vertical>
              <Grid container stackable verticalAlign='top'>
                <Grid.Row>
                  <Grid.Column floated='right' width={6} style={{marginLeft: 0, marginRight: '8rem'}}>
                    <Image rounded size='large' src={'/team1.jpg'} />
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Header as='h3' style={{ fontSize: '2em' }}>
                      Unsere Werte
                    </Header>
                    <Container style={{ fontSize: '1.15rem' }}>Wir leben unsere Werte jeden Tag. Die Kernwerte lassen sich in drei Schlagworten zusammenfassen: Authentizität, Integrität und Neugier. Sie interessiert, was dahintersteckt und wie wir unsere Werte leben? Sprechen Sie uns an!</Container>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                </Grid.Row>
              </Grid>
            </Segment>

            <Segment style={{ padding: '5em 0em' }} vertical>
              <Grid container stackable verticalAlign='top'>
                <Grid.Row>
                <Grid.Column width={8}>
                    <Header as='h3' style={{ fontSize: '2em' }}>
                      Das Team
                    </Header>
                    <Container style={{ fontSize: '1.15rem' }}>Unser Team ist m|rig und m|rig ist das Team. Wir leben ein Miteinander und lernen voneinander und unsere Arbeit macht uns Spaß.</Container>
                    <Divider hidden></Divider>
                    <Container style={{ fontSize: '1.15rem' }}>Unsere fundierte quantitative Ausbildung bringen wir lösungsorientiert für unsere Kunden ein. Uns interessiert das fachliche Weiterkommen. Das dafür notwendige gemeinschaftliche Klima ist uns sehr wichtig. Wir diskutieren offen über Ideen. Auch Fehlschläge sind kein Tabuthema und geben immer einen Anlass zu lernen. Hier hilft jeder jedem!</Container>
                  </Grid.Column>
                  <Grid.Column floated='right' width={6}>
                    <Image rounded size='large' src={'/team2.jpg'} />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                </Grid.Row>
              </Grid>
            </Segment>

            <Segment style={{ padding: '5em 0em' }} vertical>
              <Grid container stackable verticalAlign='top'>
                <Grid.Row>
                  <Grid.Column floated='right' width={6} style={{marginLeft: 0, marginRight: '8rem'}}>
                    <Image rounded size='large' src={'/team3.jpg'} />
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Header as='h3' style={{ fontSize: '2em' }}>
                      Unsere Strategie
                    </Header>
                    <Container style={{ fontSize: '1.15rem' }}>Unsere Kompetenz im quantitativen Risikomanagement ist die Basis von m|rig. Wir unterstützen immer mehr Kunden und wachsen stetig. Bei den anstehenden Herausforderungen der Banken an Quantität und Qualität von Reportings, Vorhersagesystemen und Ad hoc-Analysen unterstützen wir unsere Kunden.</Container>
                    <Divider hidden></Divider>
                    <Container style={{ fontSize: '1.15rem' }}>Immer mehr Banken wollen im Bereich Künstlicher Intelligenz Projekte durchführen und Erfahrungen sammeln. Wir konnten bereits die ersten Kunden erfolgreich unterstützen und sehen steigenden Bedarf. In diesem Bereich bauen wir massiv auf und werden in die Zukunft unserer Kunden investieren.</Container>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                </Grid.Row>
              </Grid>
            </Segment>

          </Segment>
        </Container>
      </div>
    );
  }
};

export default TeamPageLayout;
