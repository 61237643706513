import React, { Component } from 'react';
import {
	Container,
	Header,
  Segment,
  Grid
} from 'semantic-ui-react'

class HomepageLayout extends Component {

	HomepageHeading() {
    return (
      <div
        inverted
            textAlign='center'
            style={{ minHeight: 650, 
              padding: '0em 0em',
              backgroundColor:'rgba(0,0,0,0.5)',
              backgroundImage: `url(${process.env.PUBLIC_URL + '/homepage.jpg'})`, 
              backgroundSize: 'cover',
              backgroundAttachment:'fixed' }}
            vertical>
        <Segment
            inverted
            textAlign='center'
            style={{ minHeight: 650, 
              padding: '1em 0em',
              backgroundColor:'rgba(0,0,0,0.4)',
              backgroundSize: 'cover',
              backgroundAttachment:'fixed' }}
            vertical
          >
        <Header
          as='h1'
          content='m|rig'
          inverted
          style={{
            fontSize: '5.2em',
            fontWeight: 'normal',
            marginBottom: 0,
            marginTop:  '2.7em',
            fontFamily: 'Verdana, sans-serif'
          }}
        />
        <Header
          as='h2'
          content='Risikomanagement und Machine Learning'
          inverted
          style={{
            fontSize:  '1.7em',
            fontWeight: 'normal',
            marginTop:  '1em',
          }}
        />
      </Segment>
      </div>
      
    );
	};

	render () {
    const header = this.HomepageHeading()
    return (
      <div> 
        {header}
        <Container>
        <Segment style={{  margin: '1em 0em 0em', padding: '3em 0em' }} vertical>
        <Grid centered columns={1}>
        <Header as='h2' centered>
        <Header.Content>
          Willkommen bei m|rig
        </Header.Content>
        <Header.Subheader>
          <p></p>
          <p>Die m|rig GmbH ist ein Beratungsunternehmen, das auf Risikomanagement in der Finanzbranche spezialisiert ist.</p>
          <p>Wir verfügen insbesondere über eine langjährige Erfahrung in allen quantitativen Methoden, die für das Risikocontrolling eingesetzt werden, und entwickeln sie mit Machine-Learning Ansätzen weiter.</p>
        </Header.Subheader>
        </Header>
        <Header></Header>
        </Grid>
        </Segment>
        
        
        </Container>
      </div>
    );
  }
};

export default HomepageLayout;
