import React, { Component } from 'react';
import { Route } from "react-router-dom";

import HomePageLayout from './homePageLayout/homePageLayout';
import CompanyPageLayout from './companyPageLayout/companyPageLayout';
import TeamPageLayout from './teamPageLayout/teamPageLayout';
import JobsPageLayout from './jobsPageLayout/jobsPageLayout';
import ContactPageLayout from './contactPageLayout/contactPageLayout';
import ImpressumPageLayout from './impressumPageLayout/impressumPageLayout';

class Page extends Component {
    render() {
        return (
            <div>
                <Route path="/" exact component={HomePageLayout} />
                <Route path="/company" exact component={CompanyPageLayout} />
                <Route path="/team" exact component={TeamPageLayout} />
                <Route path="/jobs" exact component={JobsPageLayout} />
                <Route path="/contact" exact component={ContactPageLayout} />
                <Route path="/impressum" exact component={ImpressumPageLayout} />
            </div>
        );
    }
}

export default Page;