import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
	Container,
	Header,
  Segment,
  Grid,
  Image,
  Icon,
  Button
} from 'semantic-ui-react'

class ContactPageLayout extends Component {

	Heading() {
    return (
      <div
        inverted
            textAlign='center'
            style={{ minHeight: 350, 
              padding: '0em 0em',
              backgroundColor:'rgba(0,0,0,0.5)',
              backgroundImage: `url(${process.env.PUBLIC_URL + '/homepage.jpg'})`, 
              backgroundSize: 'cover',
              backgroundAttachment:'fixed' }}
            vertical>
        <Segment
            inverted
            textAlign='center'
            style={{ minHeight: 350, 
              padding: '1em 0em',
              backgroundColor:'rgba(0,0,0,0.4)',
              backgroundSize: 'cover',
              backgroundAttachment:'fixed' }}
            vertical
          >
        <Header
          as='h1'
          content='Kontakt'
          inverted
          style={{
            fontSize: '5.2em',
            fontWeight: 'normal',
            marginTop:  '1.7em',
          }}
        />
        <Header
          as='h2'
          content='Kontaktieren Sie uns für weitere Informationen'
          inverted
          style={{
            fontSize:  '1.7em',
            fontWeight: 'normal',
            marginTop:  '1em',
          }}
        />
      </Segment>
      </div>
      
    );
	};

	render () {
    const header = this.Heading()
    return (
      <div> 
        {header}
        <Container>
          <Segment style={{  margin: '1em 1em 0em', padding: '3em 0em' }} vertical>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <Header as='h1' textAlign='center'>Tobias Neugebauer</Header>
                <center>
                  <Image src={'/tobias.jpg'} circular size='small'/>
                </center>
                  <Header as='h4' textAlign='center'>
                    <Icon name='phone'/> +49 160 75 31 048
                  </Header>
                  <Header as='h4' textAlign='center'>
                    <Icon name='mail'/> tobias.neugebauer@mrig.de
                  </Header>
                  <Header as='h4' textAlign='center'>
                    <Link to="/tobias_neugebauer.vcf" target="_blank" download style={{color: '#212121'}}><Icon name='address card' size='large' />vCard</Link>
                  </Header>
              </Grid.Column>

              <Grid.Column width={8}>
                <Header as='h1' textAlign='center'>Maged Hajal</Header>
                <center>
                  <Image src={'/maged.jpg'} circular size='small'/>
                </center>
                <Header as='h4' textAlign='center'>
                  <Icon name='phone'/> +49 160 32 46 806
                </Header>
                <Header as='h4' textAlign='center'>
                  <Icon name='mail'/> maged.hajal@mrig.de
                </Header>
                <Header as='h4' textAlign='center'>
                  <Link to="/maged_hajal.vcf" target="_blank" download style={{color: '#212121'}}><Icon name='address card' size='large' />vCard</Link>
                </Header>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          </Segment>
        </Container>

        <Segment style={{ backgroundColor: '#3DA7CA', paddingTop: '60px', paddingBottom: '60px' }} vertical>
          <Header as='h1' textAlign='center' style={{ color: 'white', paddingBottom: '15px' }}>Haben Sie weitere Fragen?</Header>
          <center>
            <Button href = "mailto: info@mrig.de" circular size='big' style={{backgroundColor: '#9A4856', color: 'white'}}>
              <Icon name='mail' /> Nachricht senden
            </Button>
          </center>
        </Segment>
      </div>
    );
  }
};

export default ContactPageLayout;

/* B4505F */
/* D24E42 */