import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
	Container,
	Header,
  Segment,
  Grid,
  Image,
  List,
  Button,
  Icon,
  Modal,
  Divider
} from 'semantic-ui-react'

class JobsPageLayout extends Component {
  DescriptionText(text) {
    if (text === 1) {
      return (
        <Modal.Content scrolling>
          <Modal.Description>
            <p>Für unser Team suchen wir hochmotivierte Personen mit überdurchschnittlich abgeschlossenem Hochschulstudium. Die folgenden Eigenschaften sind ein Muss für uns:</p>
            <List bulleted>
              <List.Item>Teamfähigkeit</List.Item>
              <List.Item>fachliche Neugier</List.Item>
              <List.Item>Begeisterungsfähigkeit</List.Item>
              <List.Item>Analytisches und konzeptionelles Denkvermögen</List.Item>
              <List.Item>Interesse an statistischen Methoden</List.Item>
              <List.Item>Reisebereitschaft</List.Item>
            </List>
            <p>Im Idealfall bringen Sie einige der folgenden Fähigkeiten mit:</p>
            <List bulleted>
              <List.Item>Kenntnisse und/oder Erfahrungen im Risikocontrolling</List.Item>
              <List.Item>Statistikkenntnisse</List.Item>
              <List.Item>Kenntnisse und/oder Erfahrungen in maschinellem Lernen</List.Item>
              <List.Item>Programmierkenntnisse (Python, R, SAS, SQL, VBA)</List.Item>
            </List>
            <p>Für berufserfahrene Bewerber sind zudem die folgenden Punkte relevant:</p>
            <List bulleted>
              <List.Item>Berufserfahrung in der Finanzbranche (spezielle im Risikocontrolling)</List.Item>
              <List.Item>Erfahrungen in statistischen Modellentwicklungen bzw. Validierung</List.Item>
              <List.Item>Berufserfahrung in maschinellem Lernen</List.Item>
              <List.Item>Erfahrung in Projektarbeit/Projektmanagement</List.Item>
            </List>
            <p>Wir freuen uns über Ihre Initiativbewerbung.</p>
            <Divider hidden />
          </Modal.Description>
        </Modal.Content>
      )
    }
    else if (text === 2) {
      return (
        <Modal.Content scrolling>
          <Modal.Description>
          <p>Wir bieten Ihnen den zu Ihnen passenden Einstieg! Egal ob als Berufseinsteiger oder als erfahrener Professional - ob als Fachspezialist oder Projektleiter. Das individuelle Fortbildungs- und Förderungsprogramm ist eng mit dem persönlichen Aufstieg verknüpft. Bei uns warten Sie nicht auf eine Beförderung, bis eine entsprechende Stelle frei wird. Ihre Lernerfolge und Projekterfahrungen determinieren Ihr Weiterkommen.</p>
            <p>Unsere Hierarchien sind flach:</p>
            <List bulleted>
              <List.Item>
                <List.Content>
                  <List.Header>Consultant</List.Header>
                  <List.Description>Die Tätigkeiten sind durch Projektarbeit gekennzeichnet. Dazu gehört auch das Übernehmen von Verantwortung, eigenständigem Arbeiten und Präsentationen vor Kunden. Sie entwickeln fachliche Themen weiter und bringen Ihre eigenen Ideen ein. Sie erlernen sowohl ein allgemeines Wissen über unser Produktportfolio als auch tiefes Spezialwissen in ausgewählten Bereichen.</List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <List.Header>Senior Consultant</List.Header>
                  <List.Description>Sie führen Kundenprojekte eigenständig durch. Sie haben die fachliche Verantwortung für ein Thema, welches Sie für m|rig weiterentwickeln.</List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <List.Header>Manager</List.Header>
                  <List.Description>Auf diesem Level werden Ihre Aufgaben individuell auf Sie zugeschnitten. Hierzu können neben Fachverantwortungen auch Projektverantwortungen und Akquisetätigkeiten kommen. Sie managen Ihre Themen, d. h. Sie verantworten Ressourcen und Entscheidungen.</List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <List.Header>Senior Manager</List.Header>
                  <List.Description>Als Senior Manager arbeiten Sie gezielt an der Unternehmensstrategie mit.</List.Description>
                </List.Content>
              </List.Item>
            </List>
              <Divider hidden />
          </Modal.Description>
        </Modal.Content>
      )
    }
    else if (text === 3) {
      return (
        <Modal.Content scrolling>
          <Modal.Description>
            <p>Bei uns erwartet Sie ein hilfsbereites Team von Spezialisten, mit dem Sie auf hohem Niveau arbeiten können. Wir stehen bedingungslos füreinander ein. Unsere Werte leben wir tagtäglich und lassen uns daran messen. Die Begeisterung für unsere Themen merkt man uns an. In diesem Umfeld freuen wir uns immer über frischen Wind. Neue Mitarbeiter werden nahtlos integriert und bekommen Verantwortung von Anfang an.</p>
            <p>Alle Mitarbeiter werden ständig weitergebildet. Ein individueller Plan ist für jeden Mitarbeiter die Grundlage für seine Entwicklung. Dieser Plan wird anhand seiner Rolle (z.B. Machine Learning Spezialist oder Managing Consultant) zusammen erarbeitet und durchgeführt. Er umfasst sowohl Hard- als auch Softskills und wird ggf. von externen Schulungen flankiert. Wir investieren in die Zukunft unserer Mitarbeiter.</p>
            <p>Unser durchweg positives Arbeitsklima lässt Raum für kritische Anmerkungen und ist gleichzeitig der Motor für Innovationen.</p>
            <Divider hidden />
        </Modal.Description>
      </Modal.Content>
      )
    }
    else if (text === 4) {
      return (
        <Modal.Content scrolling>
          <Modal.Description>
          <p>Sie sind Student und möchten das Arbeitsleben durch ein Praktikum kennenlernen? Super, bei uns sind Sie richtig. Wir bieten Ihnen:</p>
          <List bulleted>
            <List.Item>statistische Analysen in R, Python, SAS und Excel</List.Item>
            <List.Item>Ergebnisaufbereitung und eigenständige Präsentation</List.Item>
            <List.Item>Erarbeitung von neuen Innovativthemen inklusive Prototyping</List.Item>
          </List>
          <p>Was Sie mitbringen sollten:</p>
          <List bulleted>
            <List.Item>erfolgreiches Studium von mindestens 4 Semestern im Bereich Naturwissenschaften oder Wirtschaftswissenschaften</List.Item>
            <List.Item>Interesse an statistischen Analysen</List.Item>
            <List.Item>Erfahrungen in mindestens einer Programmiersprache oder Statistiksoftware</List.Item>
            <List.Item>Kenntnisse von Microsoft Office (Excel, Word und PowerPoint)</List.Item>
            <List.Item>Lernbereitschaft, Teamfähigkeit und gewissenhaftes Arbeiten</List.Item>
          </List>
            <Divider hidden />
        </Modal.Description>
      </Modal.Content>
      )
    }
    else {
      return ('')
    }
   }

  Modal(text) {
    var header = ''
    if (text === 1) {
      header = 'Wen suchen wir?'
    }
    else if (text === 2) {
      header = 'Ein- und Aufstiegschancen'
    }
    else if (text === 3) {
      header = 'Arbeiten bei m|rig'
    }
    else if (text === 4) {
      header = 'Praktikant'
    }

    return (
      <Modal dimmer='blurring' trigger={<Button primary size='large' style={{backgroundColor: '#22334D'}}>Mehr lesen<Icon name='right chevron' /> </Button>}>
        <Modal.Header>{header}</Modal.Header> 
        {this.DescriptionText(text)}
        <Modal.Actions>
          <Button as={Link} to='/contact' primary style={{backgroundColor: '#3DA7CA'}}>
            Jetzt bewerben
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }


	Heading() {
    return (
      <div
        inverted
            textAlign='center'
            style={{ minHeight: 350, 
              padding: '0em 0em',
              backgroundColor:'rgba(0,0,0,0.5)',
              backgroundImage: `url(${process.env.PUBLIC_URL + '/homepage.jpg'})`, 
              backgroundSize: 'cover',
              backgroundAttachment:'fixed' }}
            vertical>
        <Segment
            inverted
            textAlign='center'
            style={{ minHeight: 350, 
              padding: '1em 0em',
              backgroundColor:'rgba(0,0,0,0.4)',
              backgroundSize: 'cover',
              backgroundAttachment:'fixed' }}
            vertical
          >
        <Header
          as='h1'
          content='Jobs'
          inverted
          style={{
            fontSize: '5.2em',
            fontWeight: 'normal',
            marginTop:  '1.7em',
          }}
        />
        <Header
          as='h2'
          content='Arbeiten bei m|rig'
          inverted
          style={{
            fontSize:  '1.7em',
            fontWeight: 'normal',
            marginTop:  '1em',
          }}
        />
      </Segment>
      </div>
      
    );
	};

	render () {
    const header = this.Heading()
    return (
      <div> 
        {header}
        <Container>
          <Segment style={{  margin: '1em 0em 0em', padding: '3em 0em' }} vertical>
            <Segment style={{ padding: '5em 0em' }} vertical>
              <Grid container stackable verticalAlign='top'>
                <Grid.Row>
                  <Grid.Column floated='right' width={6} style={{marginLeft: 0, marginRight: '8rem'}}>
                    <Image rounded size='large' src={'/jobs1.jpg'} />
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Header as='h3' style={{ fontSize: '2em' }}>
                      Wen suchen wir?
                    </Header>
                    <Container textAlign='justified' style={{ fontSize: '1.15rem' }}>Teamfähige, motivierte Kollegen mit abgeschlossenem Hochschulstudium sind bei uns immer willkommen. Ihre fachliche Neugier sollte die Bereiche Machine Learning, Statistik, Finanzbranche und/oder Projektmanagement streifen. Neben Datenanalysen ist auch die Kommunikation mit unseren Kunden eine Priorität.</Container>
                    <Divider hidden></Divider>
                    <Container textAlign='justified' style={{ fontSize: '1.15rem' }}>Berufserfahrung in den relevanten Bereichen ist von Vorteil.</Container>
                    <Divider hidden></Divider>
                    {this.Modal(1)}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                </Grid.Row>
              </Grid>
            </Segment>

            <Segment style={{ padding: '5em 0em' }} vertical>
              <Grid container stackable verticalAlign='top'>
                <Grid.Row>
                <Grid.Column width={8}>
                    <Header as='h3' style={{ fontSize: '2em' }}>
                      Ein- und Aufstiegschancen
                    </Header>
                    <Container textAlign='justified' style={{ fontSize: '1.15rem' }}>Wir bieten Ihnen den zu Ihnen passenden Einstieg! Egal ob als Berufseinsteiger oder als erfahrener Professional - ob als Fachspezialist oder Projektleiter. Das individuelle Fortbildungs- und Förderungsprogramm ist eng mit dem persönlichen Aufstieg verknüpft.</Container>
                    <Divider hidden></Divider>
                    <Container textAlign='justified' style={{ fontSize: '1.15rem' }}>Bei uns warten Sie nicht auf eine Beförderung, bis eine entsprechende Stelle frei wird. Ihre Lernerfolge und Projekterfahrungen determinieren Ihr Weiterkommen.</Container>
                    <Divider hidden></Divider>
                    {this.Modal(2)}
                  </Grid.Column>
                  <Grid.Column floated='right' width={6}>
                    <Image rounded size='large' src={'/jobs2.jpg'} />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                </Grid.Row>
              </Grid>
            </Segment>

            <Segment style={{ padding: '5em 0em' }} vertical>
              <Grid container stackable verticalAlign='top'>
                <Grid.Row>
                  <Grid.Column floated='right' width={6} style={{marginLeft: 0, marginRight: '8rem'}}>
                    <Image rounded size='large' src={'/jobs3.jpg'} />
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Header as='h3' style={{ fontSize: '2em' }}>
                      Arbeiten bei m|rig
                    </Header>
                    <Container textAlign='justified' style={{ fontSize: '1.15rem' }}>Bei uns erwartet Sie ein hilfsbereites Team von Spezialisten, mit dem Sie auf hohem Niveau arbeiten können. Wir stehen bedingungslos füreinander ein. Unsere Werte leben wir tagtäglich und lassen uns daran messen. Die Begeisterung für unsere Themen merkt man uns an.</Container>
                    <Divider hidden></Divider>
                    <Container textAlign='justified' style={{ fontSize: '1.15rem' }}>In diesem Umfeld freuen wir uns immer über frischen Wind. Neue Mitarbeiter werden nahtlos integriert und bekommen Verantwortung von Anfang an.</Container>
                    <Divider hidden></Divider>
                    {this.Modal(3)}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                </Grid.Row>
              </Grid>
            </Segment>

            <Segment style={{ padding: '5em 0em' }} vertical>
              <Grid container stackable verticalAlign='top'>
                <Grid.Row>
                <Grid.Column width={8}>
                    <Header as='h3' style={{ fontSize: '2em' }}>
                      Praktikant
                    </Header>
                    <Container textAlign='justified' style={{ fontSize: '1.15rem' }}>Sie sind Student und möchten das Arbeitsleben durch ein Praktikum kennenlernen? Super, bei uns sind Sie richtig. Wir bieten Ihnen:</Container>
                    <List style={{ fontSize: '1.15rem' }} bulleted>
                      <List.Item>statistische Analysen in R, Python, SAS und Excel</List.Item>
                      <List.Item>Ergebnisaufbereitung und eigenständige Präsentation</List.Item>
                      <List.Item>Erarbeitung von neuen Innovativthemen inklusive Prototyping</List.Item>
                    </List>
                    <Divider hidden></Divider>
                    {this.Modal(4)}
                  </Grid.Column>
                  <Grid.Column floated='right' width={6}>
                    <Image rounded size='large' src={'/jobs4.jpg'} />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                </Grid.Row>
              </Grid>
            </Segment>
          </Segment>
        </Container>
      </div>
    );
  }
};

export default JobsPageLayout;
