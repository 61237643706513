import React, { Component } from 'react';
import {
	Container,
	Header,
  Segment
} from 'semantic-ui-react'

class ImpressumPageLayout extends Component {
	render () {
    return (
      <div> 
        <Container style={{marginBottom: '200px'}}>
          <Segment style={{  margin: '3em 1em 0em', padding: '3em 0em' }} vertical>
            <Header as='h1'>Impressum</Header>
            <p style={{marginBottom: 0}}>mrig GmbH</p>
            <p style={{marginBottom: 0}}>In der Römerstadt 253</p>
            <p style={{marginBottom: 0}}>60488 Frankfurt</p>
            <p style={{marginBottom: 0}}>Gesellschaft eingetragen beim Amtsgericht Frankfurt am Main Handelsregister B 114960</p>
            <p style={{marginBottom: 0}}>Email: impressum@mrig.de</p>
            <p style={{marginBottom: 0}}>Telefon: 0160 324 6806</p>
            <Header as='h1'>Büroadresse</Header>
            <p style={{marginBottom: 0}}>mrig GmbH</p>
            <p style={{marginBottom: 0}}>MEET/N/WORK</p>
            <p style={{marginBottom: 0}}>Am Hauptbahnhof 16</p>
            <p style={{marginBottom: 0}}>60629 Frankfurt</p>            
          </Segment>
        </Container>
      </div>
    );
  }
};

export default ImpressumPageLayout;
